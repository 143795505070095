import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { map } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Features, DetailDialog } from 'shared_components/src/components/tenants';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';

import kycApiService from '../../service/kycApi.service';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';

const FeaturesPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const featureFlags = useFlags();
	const [initialTenantSettingsData, setInitialTenantSettingsData] = useState([] as any);
	const [tenantSettings, setTenantSettings] = useState([] as any);
	const [dialogData, setDialogData] = useState({} as any);
	const [openThresholdModal, setOpenThresholdModal] = useState(false);

	const isV2Enabled = Boolean(featureFlags['document-proofing-feature']);

	useEffect(() => {
		dispatch(updateMenu(MENU.features));
	}, []);

	useEffect(() => {
		function getTenantSettings() {
			dispatch(setLoading());
			return new Promise((resolve) => {
				kycApiService
					.getSettings()
					.then((res: any) => {
						if (res) {
							setTenantSettingsData(res.features);
							setInitialTenantSettingsData(res);
						} else {
							setTenantSettings([]);
						}
					})
					.catch((err: any) => {
						setTenantSettings([]);
					})
					.finally(() => {
						dispatch(clearLoading());
						return resolve({ status: 'success' });
					});
			});
		}

		getTenantSettings();
	}, []);

	const updateTenantSettings = (data) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateTenantSettings(data)
				.then((res: any) => {
					if (res?.features) {
						displayNotificatoinSuccess('Features successfully updated.');
						setTenantSettingsData(res.features);
						setInitialTenantSettingsData(res);
					} else {
						displayNotificatoinError('Failed to update Features.');
					}
				})
				.catch((err: any) => {
					displayNotificatoinError('Failed to update Features.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const setTenantSettingsData = (features) => {
		const products = map(features, (feature, key) => ({ key, ...feature }));
		const filteredProducts = products.filter((product) => product.type === 'default');
		const faceScanCheck = filteredProducts.find((product) => product.key === 'faceScan');

		const productList = filteredProducts.map((product) => {
			if (product.key === 'ocr') {
				product.order = 1;
				product.isSelectable = false;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = !isV2Enabled;
				product.thresholdLabel = 'Confidence Threshold';
				product.showMinMaxThreshold = isV2Enabled;
				product.reScanSubtitle =
					'Checks the quality of the extraction and asks the user to re-scan if it is low quality.';
			} else if (product.key === 'identityOwnerCheck') {
				product.order = 2;
				product.isSelectable = false;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = false;
				product.showMinMaxThreshold = false;
				product.editablePerVerification = true;
				product.requiredOverridable = product.requiredOverridable;
				product.selectedIdentityOwnerSource = product.sources.find((source) => source.default)?.code;
			} else if (product.key === 'authenticityVerification') {
				product.order = 3;
				product.isSelectable = true;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = !isV2Enabled;
				product.showMinMaxThreshold = isV2Enabled;
				product.thresholdLabel = 'Confidence Threshold';
			} else if (product.key === 'faceScan') {
				product.order = 4;
				product.isSelectable = true;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = false;
				product.reScanSubtitle =
					'Checks the quality of the selfie and asks the user to re-take if it is lost quality.';
			} else if (product.key === 'liveness') {
				product.order = 5;
				product.disabled = !faceScanCheck.required;
				product.isSelectable = true;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = !isV2Enabled;
				product.showMinMaxThreshold = isV2Enabled;
				product.thresholdLabel = 'Liveness Threshold';
			} else if (product.key === 'faceMatch') {
				product.order = 6;
				product.disabled = !faceScanCheck.required;
				product.isSelectable = true;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = !isV2Enabled;
				product.showMinMaxThreshold = isV2Enabled;
				product.thresholdLabel = 'Similarity Threshold';
			} else if (product.key === 'reviewEdit') {
				product.order = 7;
				product.isSelectable = true;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = false;
				product.showMinMaxThreshold = true;
				product.thresholdLabel = 'Similarity Threshold';
			} else if (product.key === 'nameMatchVerification') {
				product.order = 8;
				product.isSelectable = true;
			} else if (product.key === 'locationCapture') {
				product.order = 9;
				product.isSelectable = true;
				product.isDetailsButtonRequired = true;
				product.editablePerVerification = true;
				product.requiredOverridable = product.requiredOverridable;
				product.isMandatory = product.isMandatory;
			}

			return product;
		});

		setTenantSettings(productList.sort((a, b) => a.order - b.order));
	};

	const updateSettings = (type, value) => {
		setTenantSettings((prev) => {
			const settings = prev.map((setting) => {
				if (type === 'faceScan') {
					if (
						setting.key === 'faceScan' ||
						setting.key === 'liveness' ||
						setting.key === 'faceMatch'
					) {
						setting.required = value;
						if (setting.key === 'liveness' || setting.key === 'faceMatch') {
							setting.disabled = !value;
						}
					}
				} else if (type === setting.key) {
					setting.required = value;
				}
				return setting;
			});
			return settings;
		});
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, type) => {
		const value = event.target.checked;

		updateSettings(type, value);
	};

	const handleShowDetails = (type) => {
		updateDialogData(type);
		setOpenThresholdModal(true);
	};

	const updateDialogData = (type) => {
		const product = tenantSettings.find((product) => type === product.key);
		setDialogData(product);
	};

	const handleDialogClose = () => {
		setOpenThresholdModal(false);
		setDialogData({});
	};

	const handleAddButtonAction = (type) => {
		updateSettings(type, true);
	};

	const handleCancelButtonAction = (type) => {
		updateSettings(type, false);
	};

	const saveSettings = () => {
		tenantSettings.forEach((product) => {
			initialTenantSettingsData.features[product.key].required = product.required;

			if (product.key === 'ocr') {
				initialTenantSettingsData.features.ocr.reScan = product.reScan;
			}

			if (product.key === 'faceScan') {
				initialTenantSettingsData.features.faceScan.reScan = product.reScan;
			}

			if (product.key === 'identityOwnerCheck') {
				initialTenantSettingsData.features.identityOwnerCheck.requiredOverridable =
					product.requiredOverridable;
				initialTenantSettingsData.features.identityOwnerCheck.sources = product.sources;
			}

			if (product.key === 'locationCapture') {
				initialTenantSettingsData.features.locationCapture.requiredOverridable = product.requiredOverridable;
				initialTenantSettingsData.features.locationCapture.isMandatory = product.isMandatory;
			}

			/**
			 * For V2 and reviewEdit, we must use min and max threshold
			 * For V1 and faceScan, we must just use threshold
			 */
			if (product.key === 'faceScan') {
				initialTenantSettingsData.features[product.key].threshold = product.threshold;
			} else if (product.key === 'reviewEdit' || isV2Enabled) {
				initialTenantSettingsData.features[product.key].maxThreshold = product.maxThreshold;
				initialTenantSettingsData.features[product.key].minThreshold = product.minThreshold;
			} else {
				initialTenantSettingsData.features[product.key].threshold = product.threshold;
			}
		});
		updateTenantSettings(initialTenantSettingsData);
	};

	const cancelSettingUpdate = () => {
		navigate(`account-details`);
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const handleDialogSave = ({
		modalFor = '',
		threshold = 0,
		minThreshold = 0,
		maxThreshold = 0,
		rescan = false,
		reScanValue = 0,
		productEditablePerVerification = false,
		newIdentityOwnerSource = '',
		isMVRequired = false,
		isMandatory = false,
	}) => {
		const value = threshold / 100;
		const minThresholdValue = minThreshold / 100;
		const maxThresholdValue = maxThreshold / 100;
		setTenantSettings((prev) => {
			const products = prev.map((product) => {
				if (modalFor === product.key) {
					/**
					 * For V2 and reviewEdit, we must use min and max threshold
					 * For V1 and faceScan, we must just use threshold
					 */
					if (product.key === 'faceScan') {
						product.threshold = value;
					} else if (product.key === 'reviewEdit' || isV2Enabled) {
						product.minThreshold = minThresholdValue;
						product.maxThreshold = maxThresholdValue;
					} else {
						product.threshold = value;
					}

					if (product.key === 'ocr') {
						product.reScan = {
							required: rescan,
							maxAttempt: reScanValue,
						};
					} else if (product.key === 'faceScan') {
						product.reScan = {
							required: rescan,
							maxAttempt: reScanValue,
						};
					} else if (product.key === 'identityOwnerCheck') {
						product.requiredOverridable = productEditablePerVerification;
						product.sources = product.sources.map((source) => {
							if (source.code === newIdentityOwnerSource) {
								source.default = true;
							} else {
								source.default = false;
							}
							return source;
						});
					} else if (product.key === 'locationCapture') {
						product.requiredOverridable = productEditablePerVerification;
						product.isMandatory = isMandatory;
					}
				}
				return product;
			});
			return products;
		});
		setOpenThresholdModal(false);
	};

	return (
		<>
			<Features
				features={tenantSettings}
				handleChange={handleChange}
				handleShowDetails={handleShowDetails}
				handleAddButtonAction={handleAddButtonAction}
				handleCancelButtonAction={handleCancelButtonAction}
				saveSettings={saveSettings}
				cancelSettingUpdate={cancelSettingUpdate}
			/>
			<DetailDialog
				open={openThresholdModal}
				type='products'
				checked={dialogData.required}
				modalFor={dialogData.key}
				thresholdLabel={dialogData.thresholdLabel}
				thresholdValue={Number(`${dialogData.threshold * 100}`)}
				thresholdRequired={dialogData.thresholdRequired}
				showMinMaxThreshold={dialogData.showMinMaxThreshold}
				maxThreshold={Number(`${dialogData.maxThreshold * 100}`)}
				minThreshold={Number(`${dialogData.minThreshold * 100}`)}
				editablePerVerification={dialogData.editablePerVerification}
				editPerVerificationChecked={dialogData.requiredOverridable}
				selectedIdentityOwnerSource={dialogData.selectedIdentityOwnerSource}
				identityOwnerSources={dialogData.sources}
				reScanFieldRequired={dialogData.reScan}
				reScan={dialogData.reScan?.required}
				reScanAttempts={dialogData.reScan?.maxAttempt}
				reScanSubtitle={dialogData.reScanSubtitle}
				title={dialogData.name}
				subTitle={dialogData.description}
				handleSave={handleDialogSave}
				handleClose={handleDialogClose}
				isMandatory={dialogData.isMandatory}
			/>
		</>
	);
};

export default FeaturesPage;
