import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Box, Skeleton, useMediaQuery, Typography } from '@mui/material';
import moment, { MomentInput } from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
	VerificationIdentityOwnerInformation,
	VerificationReportsV2,
	VerificationHeaderV2,
	VerificationProofingOverviewV2,
	OverallVerificationResultV2,
	ProofingDataV2,
	RUFCMatches,
	VerificationLocation,
} from 'shared_components/src/components/verification';
import {
	Status,
	TruuthTabs,
	TruuthTab,
	TruuthMobileAccordion,
	TruuthMobileAccordionSummary,
	TruuthMobileAccordionDetails,
	CheckResult,
} from 'shared_components/src/components/common';
import { DownArrowIcon } from 'shared_components/src/common/icons';
import { IProofingDocument, IVerificationDetailV2 } from 'shared_components/src/service/models/verification';
import VerificationOtherChecksV2 from 'shared_components/src/components/verification/VerificationOtherChecks';

import {
	IVerificationReportV2,
	IProofingOutcome,
	IOverallProofingCheck,
} from 'shared_components/src/service/models/verification';

import { _getAuthRoles } from '../../store/selectors';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import KycApiService from '../../service/kycApi.service';

/**
 * Styles
 */

/**
 * Types and Constants
 */

interface QueryParamTypes {
	tenantAlias: string;
	verId: string;
}

/**
 * Main Component
 */
const VerificationDetailPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { tenantAlias: alias, verId } = useParams();
	const smallScreen = useMediaQuery('(max-width:900px)');
	const featureFlags = useFlags();

	/**
	 * States
	 */
	const [tenantAlias, setTenantAlias] = useState('');
	const [verificationID, setVerificationID] = useState('');
	const [currentTab, setCurrentTab] = useState('IDENTITY_PROOFING');
	const [verificationDetails, setVerificationDetails] = useState<IVerificationDetailV2 | null>(null);
	const [faceImage, setFaceImage] = useState<string>('');

	const authRoles = _getAuthRoles();
	const hasOtherChecks = Boolean(verificationDetails?.results?.otherCheckOutcomes?.length);
	const showRUFCMatches =
		(featureFlags['RUFC-Matches'] &&
			verificationDetails?.results?.otherCheckOutcomes?.some(
				(check) =>
					check.type === 'repeatUserFraudCheck' &&
					['FAIL', 'WARNING'].includes(check.status?.toUpperCase())
			)) ||
		false;
	const RUFCStatus =
		verificationDetails?.results?.otherCheckOutcomes?.find(
			(check) => check.type === 'repeatUserFraudCheck'
		)?.status || 'FAIL';

	useEffect(() => {
		// If the tenantAlias or the verification ID query params are not defined, redirect user
		if (alias === undefined || verId === undefined) {
			navigate(`/tenant/${tenantAlias}/verification`);
		} else {
			setVerificationID(verId);
			setTenantAlias(alias);
		}

		// Set menu key
		dispatch(updateMenu(MENU.verificaton));
	}, [window.location.pathname]);

	useEffect(() => {
		getVerificationData();
	}, [tenantAlias, verificationID]);

	useEffect(() => {
		if (verificationDetails?.results?.faceImage) {
			getFaceImageData(verificationID).then((res) => setFaceImage(res.image || ''));
		}
	}, [verificationDetails?.results?.faceImage]);

	/* function to get the verification details  */
	function getVerificationData() {
		if (verificationID) {
			dispatch(setLoading());
			KycApiService.getVerificationDetail(verificationID)
				.then((res) => {
					dispatch(clearLoading());

					if (res && !res.error) {
						setVerificationDetails(res);
					} else {
						console.dir(res.error);
						navigate('/not-match');
					}
				})
				.catch((err) => {
					console.dir(err);
					dispatch(clearLoading());
					navigate('/not-match');
				});
		}
	}

	/* Function to get a document's meta data (fields) */
	async function getDocumentFields(document: IProofingDocument) {
		return KycApiService.getDocumentFields(document.documentType, document.documentClassificationCode);
	}

	/* Function to download a report */
	async function downloadReport(report: IVerificationReportV2) {
		const { type } = report;
		const id = verificationID;

		return KycApiService.downloadReport(id, type);
	}

	/**
	 * Get document images
	 */
	const getDocumentImages = useCallback(
		(docId: string, side: string, verId?: string) => {
			const id = verId || verificationID;
			return KycApiService.getVerificationImageByUrl(id, docId, side);
		},
		[verificationID]
	);

	/**
	 * Get face image
	 */
	const getFaceImageData = useCallback((id: string) => {
		return KycApiService.getFaceImageByUrl(id);
	}, []);

	const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
		setCurrentTab(newValue);
	};

	/**
	 * Get RUFC matching records
	 */
	const getRUFCMatchingRecords = useCallback(
		(page: number, limit: number) => {
			return KycApiService.getRUFCMatchingRecords(verificationID, page, limit);
		},
		[verificationID]
	);

	const renderProofingOutcome = () => {
		if (!smallScreen && currentTab === 'RUFC_MATCHES') {
			return (
				<RUFCMatches
					verificationID={verificationID}
					faceImage={faceImage}
					getRUFCMatchingRecords={getRUFCMatchingRecords}
					getDocumentImages={getDocumentImages}
					getFaceImage={getFaceImageData}
				/>
			);
		}

		const proofingData = verificationDetails?.results?.proofingOutcomes?.find(
			(proof) => proof.proofingTag.code === currentTab
		) as IProofingOutcome;

		return (
			<ProofingDataV2
				proofingOutcome={proofingData}
				getDocumentFields={getDocumentFields}
				handleDownloadReport={downloadReport}
				getDocumentImages={getDocumentImages}
				faceImage={faceImage}
			/>
		);
	};

	if (!verificationDetails) {
		return <Skeleton animation='wave' variant='rectangular' width='100%' height='100vh' />;
	}

	return (
		<div>
			<VerificationHeaderV2
				inviteeEmailId={verificationDetails?.inviteeDetails?.email || ''}
				inviteeMobileNumber={verificationDetails?.inviteeDetails?.mobileNumber || ''}
				verificationId={verificationDetails?.verificationId || ''}
				verificationStatus={verificationDetails?.status || ''}
				faceImage={faceImage}
			/>
			<Typography
				style={{
					marginBottom: '1rem',
					fontFamily: 'Inter, sans-serif',
					fontWeight: 400,
					fontSize: '14px',
					lineHeight: '20px',
					color: '#515170',
				}}
			>
				{`Verification completed on ${moment(verificationDetails.updatedAt as MomentInput).format(
					'MMMM D YYYY, h:mm A'
				)}`}
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6} lg={hasOtherChecks ? 3 : 4}>
					<VerificationIdentityOwnerInformation
						identityOwner={{
							firstName: verificationDetails?.identityOwner?.givenName || '',
							middleName: verificationDetails?.identityOwner?.middleName || '',
							lastName: verificationDetails?.identityOwner?.familyName || '',
							dateOfBirth: verificationDetails?.identityOwner?.dateOfBirth || '',
							gender: verificationDetails?.identityOwner?.gender || '',
							mobileNumber: verificationDetails?.identityOwner?.mobileNumber || '',
						}}
						inviteeDetails={{
							...verificationDetails?.inviteeDetails,
							customerRef: verificationDetails?.externalRefId,
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={hasOtherChecks ? 3 : 4}>
					<VerificationProofingOverviewV2
						proofingOutcome={verificationDetails.results?.proofingOutcomes}
					/>
				</Grid>
				{verificationDetails.results?.otherCheckOutcomes &&
					verificationDetails.results?.otherCheckOutcomes.length > 0 && (
						<Grid item xs={12} md={6} lg={3}>
							<VerificationOtherChecksV2
								otherCheckOutcomes={verificationDetails.results?.otherCheckOutcomes || []}
							/>
						</Grid>
					)}
				<Grid item xs={12} md={6} lg={hasOtherChecks ? 3 : 4}>
					<VerificationReportsV2
						reports={verificationDetails.results?.reports}
						handleDownloadReport={downloadReport}
					/>
					{verificationDetails.deviceLocation && (
						<VerificationLocation deviceLocation={verificationDetails.deviceLocation} />
					)}
				</Grid>
			</Grid>
			{smallScreen ? (
				<Box sx={{ marginTop: '2rem' }}>
					{verificationDetails.results?.proofingOutcomes?.map((proof) => (
						<TruuthMobileAccordion key={proof.proofingTag?.code}>
							<TruuthMobileAccordionSummary
								id={proof.proofingTag?.code}
								expandIcon={<DownArrowIcon />}
							>
								<CheckResult
									status={proof.proofingStatus}
									checkName={proof.proofingTag?.name}
								/>
							</TruuthMobileAccordionSummary>
							<TruuthMobileAccordionDetails>
								{renderProofingOutcome()}
							</TruuthMobileAccordionDetails>
						</TruuthMobileAccordion>
					))}
					{showRUFCMatches && (
						<TruuthMobileAccordion key='rufc-matches'>
							<TruuthMobileAccordionSummary id='rufc-matches' expandIcon={<DownArrowIcon />}>
								<CheckResult status={RUFCStatus} checkName='RUFC Matches' />
							</TruuthMobileAccordionSummary>
							<TruuthMobileAccordionDetails>
								<RUFCMatches
									verificationID={verificationID}
									faceImage={faceImage}
									getRUFCMatchingRecords={getRUFCMatchingRecords}
									getDocumentImages={getDocumentImages}
									getFaceImage={getFaceImageData}
								/>
							</TruuthMobileAccordionDetails>
						</TruuthMobileAccordion>
					)}
				</Box>
			) : (
				<React.Fragment>
					<Box sx={{ marginTop: '2rem' }}>
						<TruuthTabs
							value={currentTab}
							onChange={handleTabChange}
							variant='scrollable'
							scrollButtons={false}
							aria-label='scrollable tabs'
							TabIndicatorProps={{
								style: { display: 'none' },
							}}
						>
							{verificationDetails.results?.proofingOutcomes?.map((proof) => (
								<TruuthTab
									key={proof.proofingTag.code}
									icon={<Status status={proof.proofingStatus} />}
									iconPosition='start'
									label={proof.proofingTag.name}
									value={proof.proofingTag.code}
								/>
							))}
							{showRUFCMatches && (
								<TruuthTab
									key='rufc-matches'
									icon={<Status status={RUFCStatus} />}
									iconPosition='start'
									label='RUFC Matches'
									value='RUFC_MATCHES'
								/>
							)}
						</TruuthTabs>
					</Box>
					{renderProofingOutcome()}
				</React.Fragment>
			)}
		</div>
	);
};

export default VerificationDetailPage;
